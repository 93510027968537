<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile mt-2">
        <div class="column is-8-tablet is-12-mobile">
          <div class="columns is-multiline is-mobile">
            <div
              class="column is-12-mobile"
              :class="multiple_sizes ? 'is-3-tablet' : 'is-6-tablet'"
            >
              <b-field
                addons
                :disabled="disableOptions || sold"
              >
                <p
                  class="control"
                  :class="multiple_sizes ? 'is-expanded' : ''"
                >
                  <b-select-validation
                    v-model="selected_size_type"
                    expanded
                    rules="required"
                    vid="selected_size_type"
                    :disabled="disableOptions || sold"
                    :label="$t('size_type')"
                  >
                    <template>
                      <option
                        v-for="opt in sizesTypes.list"
                        :key="opt.id"
                        :value="opt.id"
                      >
                        {{ opt.label }}
                      </option>
                    </template>
                  </b-select-validation>
                </p>
                <p
                  v-if="!multiple_sizes"
                  class="control is-expanded"
                >
                  <b-autocomplete-validation
                    v-model="selected_size"
                    rules="required"
                    :expanded="true"
                    :label="$t('size')"
                    :data="sizes"
                    :placeholder="$t('choose')"
                    vid="size"
                    :disabled="disableOptions || sold"
                    addons
                    item-text="label"
                    field="id"
                    :clear-on-data-change="false"
                  >
                    <template #addon="{props}">
                      <p
                        v-if="$can('create', 'catalog')"
                        v-show="props.isEmpty"
                        class="control"
                        @click="
                          showNewAttribute(
                            'size',
                            selected_size_type,
                            props.search
                          )
                        "
                      >
                        <b-button
                          :disabled="disableOptions"
                          class="button"
                        >
                          <b-icon
                            icon="plus"
                            type="is-grey-light"
                          />
                        </b-button>
                      </p>
                    </template>
                  </b-autocomplete-validation>
                </p>
              </b-field>
            </div>
            <div class="column is-3-tablet is-6-mobile mt-2">
              <b-switch
                v-model="shopping_feed"
                :disabled="disableOptions"
              >
                {{
                  $t("shopping_feed")
                }}
              </b-switch>
            </div>
            <div class="column is-3-tablet is-6-mobile mt-2">
              <b-switch
                v-model="pack"
                :disabled="disableOptions"
              >
                {{
                  $tc("pack", 1)
                }}
              </b-switch>
            </div>
            <!-- <div class="column is-2-tablet is-6-mobile mt-2">
              <b-switch
                v-model="sample"
                :disabled="disableOptions"
              >
                {{
                  $t("sample")
                }}
              </b-switch>
            </div> -->
            

            <div
              v-if="multiple_sizes"
              class="column is-12-mobile is-9-tablet"
            >
              <multiSelect
                v-model="selected_size"
                :label="$t('size')"
                rules="required"
                vid="multiselect"
                :data="sizes"
                :disabled="disableOptions || sold"
                item-text="label"
                item-id="id"
              />
            </div>

            <div
              v-else
              class="column is-3-tablet is-6-mobile"
            >
              <b-input-validation
                v-model="barcode"
                :placeholder="placeholders.product.barcode"
                vid="barcode"
                :rules="'valid_barcode:' + (action === 'edit' ? $route.params.sku : null)"
                :disabled="disableOptions"
                :label="`${$t('barcode')} (${$t('optional')})`"
              />
            </div>

            <div class="column is-3-tablet is-6-mobile">
              <b-input-validation
                v-model="weight"
                :placeholder="placeholders.number['3']"
                rules="required"
                input-type="number"
                vid="weight"
                :disabled="disableOptions"
                :label="$t('weight') + ' (g)'"
              />
            </div>

            <div class="column is-6-tablet is-12-mobile is-flex">
              <b-autocomplete-validation
                v-model="attribute_value"
                class="is-flex-grow-1"
                rules="required"
                :label="attribute_label"
                expanded
                :data="attributes"
                vid="attribute"
                :disabled="disableOptions || sold"
                item-text="label"
                field="id"
                :addons="$can('create', 'catalog')"
                :clear-on-data-change="false"
              >
                <template #addon="{props}">
                  <p
                    v-if="$can('create', 'catalog.attributes')"
                    slot="addon"
                    class="control"
                  >
                    <b-button
                      :disabled="!props.isEmpty || disableOptions"
                      class="button"
                      @click="
                        showNewAttribute(
                          'flavor-color',
                          attribute_id,
                          props.search
                        )
                      "
                    >
                      <b-icon
                        icon="plus"
                        type="is-grey-light"
                      />
                    </b-button>
                  </p>
                </template>
              </b-autocomplete-validation>

              <span
                v-if="action == 'create'"
                class="is-clickable ml-2 mt-2"
                @click="switchAttributes"
              >
                <b-icon
                  icon="swap-horizontal"
                  type="is-grey"
                  custom-size="mdi-18px"
                />
              </span>
            </div>

            <div class="column is-3-tablet is-6-mobile">
              <b-input-validation
                v-model="hs_code"
                class="is-flex-grow-1"
                :label="`${$t('hs_code')} (${$t('optional')})`"
                vid="hs_code"
                :placeholder="placeholders.product.hs_code"
                :disabled="disableOptions"
              />
            </div>

            <div class="column is-3-tablet is-6-mobile">
              <b-autocomplete-validation
                v-model="origin_iso"
                class="is-flex-grow-1"
                :label="`${$t('origin_iso')} (${$t('optional')})`"
                expanded
                :data="countries.list"
                :disabled="disableOptions"
                item-text="label"
                field="id"
                addons
                :clear-on-data-change="false"
              />
            </div>

            <div
              v-for="(attr, index) in generalAttributes"
              :key="index"
              class="column is-6-tablet is-12-mobile"
            >
              <b-field
                addons
                :disabled="disableOptions"
              >
                <p class="control">
                  <b-select-validation
                    v-model="attr.type"
                    expanded
                    :disabled="disableOptions"
                    :label="$t('type')"
                    hide-details
                  >
                    <template>
                      <option
                        v-for="opt in generalAttributesData"
                        :key="opt.id"
                        :disabled="
                          generalAttributes.findIndex(g => g.type == opt.id) !=
                            -1
                        "
                        :value="opt.id"
                      >
                        {{ opt.label }}
                      </option>
                    </template>
                  </b-select-validation>
                </p>
                <p class="control is-expanded">
                  <b-autocomplete-validation
                    :key="'autocomplete_' + index"
                    :value="attr.value"
                    expanded
                    :label="$tc('value', 1)"
                    :data="getTypeData(attr.type)"
                    :placeholder="$t('choose')"
                    :disabled="disableOptions || !attr.type"
                    item-text="value"
                    field="id"
                    hide-details
                    addons
                    :clear-on-data-change="false"
                    @change="
                      val => {
                        if (val) {
                          attr.value = val;
                          if (index == generalAttributes.length - 1)
                            generalAttributes.push({ id: null, value: null });
                        }
                      }
                    "
                  >
                    <template #addon="{props}">
                      <p
                        v-if="$can('create', 'catalog.attributes')"
                        v-show="props.isEmpty"
                        class="control"
                        @click="
                          showNewAttribute('attribute', attr.type, props.search)
                        "
                      >
                        <b-button
                          :disabled="disableOptions || !attr.type"
                          class="button"
                        >
                          <b-icon
                            icon="plus"
                            type="is-grey-light"
                          />
                        </b-button>
                      </p>
                    </template>
                  </b-autocomplete-validation>
                </p>
              </b-field>
            </div>
          </div>
        </div>

        <div class="column is-4-tablet is-12-mobile mt-2">
          <div
            class="bg-cream columns is-mobile is-multiline is-variable is-2 px-2"
          >
            <div class="column is-12 is-size-5">
              <b>{{ $t("logistic") }}</b>
            </div>

            <!-- <div class="column is-6 mb-3">
              <b-switch v-model="label" :disabled="disableOptions">{{
                $t("label")
              }}</b-switch>
            </div>

             -->

            <div class="column is-6-tablet is-12-mobile">
              <b-autocomplete-validation
                v-model="qty_palette"
                class="is-flex-grow-1"
                :label="`${$t('qty_palette')} (${$t('optional')})`"
                expanded
                :placeholder="placeholders.number['3']"
                :data="qtyPalettes.list"
                :disabled="disableOptions"
                item-text="value"
                field="id"
                addons
                :clear-on-data-change="false"
              >
                <template #addon="{props}">
                  <p
                    v-if="$can('create', 'catalog')"
                    v-show="props.isEmpty"
                    slot="addon"
                    class="control"
                    @click="
                      showNewAttribute(
                        'qty_palette',
                        qtyPalettes.id,
                        props.search
                      )
                    "
                  >
                    <b-button
                      :disabled="disableOptions"
                      class="button"
                    >
                      <b-icon
                        icon="plus"
                        type="is-grey-light"
                      />
                    </b-button>
                  </p>
                </template>
              </b-autocomplete-validation>
            </div>

            <div class="column is-6-tablet is-12-mobile">
              <b-autocomplete-validation
                v-model="qty_box"
                class="is-flex-grow-1"
                :label="`${$t('qty_carton')} (${$t('optional')})`"
                :placeholder="placeholders.number['2']"
                :data="qtyBoxes.list"
                :disabled="disableOptions"
                item-text="value"
                field="id"
                addons
                expanded
                :clear-on-data-change="false"
              >
                <template #addon="{props}">
                  <p
                    v-if="$can('create', 'catalog')"
                    v-show="props.isEmpty"
                    slot="addon"
                    class="control"
                    @click="
                      showNewAttribute('qty_box', qtyBoxes.id, props.search)
                    "
                  >
                    <b-button
                      :disabled="disableOptions"
                      class="button"
                    >
                      <b-icon
                        icon="plus"
                        type="is-grey-light"
                      />
                    </b-button>
                  </p>
                </template>
              </b-autocomplete-validation>
            </div>

            <div class="column is-6-tablet is-12-mobile">
              <SearchProduct
                :label="`${$t('parent_id')} (${$t('optional')})`"
                type="sku"
                :disabled="disableOptions"
                :placeholder="placeholders.product.flavor"
                :selected-prod="parent_id"
                :sites-ids="[1]"
                :prod-id="prodId"
                :warehouse-id="warehouse_id"
                @selectItem="product => (parent_id = product.id)"
              />
            </div>

            <!-- <div class="column is-6-tablet is-12-mobile">
              <b-select-validation
                v-model="car_category"

                :label="`${$t('car_category')} (${$t('optional')})`"
                :disabled="disableOptions"
              >
                <template>
                  <option
                    v-for="opt in carCategories"
                    :key="opt"
                    :value="opt"
                  >
                    {{
                      opt
                    }}
                  </option>
                </template>
              </b-select-validation>
            </div> -->
          </div>        
        </div>

        <div class="column is-12 is-size-4 pb-0">
          <b>{{ $tc("warehouse", 2) }}</b>
        </div>
        <div
          v-for="w, index in warehouses"
          :key="w.id"
          class="column is-12-tablet is-12-mobile pb-0"
        >        
          <div class="columns is-mobile is-multiline is-variable">
            <div class="column is-2-tablet is-12-mobile is-size-6 is-align-items-center is-flex is-justify-content-space-between mt-2">
              <div class="wrap">
                <img
                  :src="'/img/flags/' + w.country.iso.toUpperCase() + '.svg'"
                  width="18"
                  height="18"
                  class="mr-2"
                >
                <b>{{ w.country.country + ' - ' + w.currency.code }}</b>
              </div>
              <span
                v-if="w.showDeleteWarehouse && $can('delete', 'catalog.warehouses')"
                class="is-clickable"
                @click="confirmDeleteWarehouse(w.id, index)"
              >
                <b-icon
                  icon="delete"
                  type="is-danger"
                  custom-size="mdi-18px"
                />
              </span> 
            </div>
            <div v-if="!$device.mobile" class="column is-10"></div> 
            <b-autocomplete-validation
              v-if="w.rerender"
              v-model="w.suppliers.selected"
              class="column is-2-tablet is-12-mobile"
              :rules="suppliers_required"
              :expanded="true"
              :label="$tc('supplier', 1)"
              :data="w.suppliers.list"
              :placeholder="$t('choose')"
              vid="supplier"
              :disabled="disableWarehouseOptions"
              item-text="label"
              field="id"
              @input="checkSuppliers"
            />
            <div v-else class="column is-2-tablet is-12-mobile"></div>
            <b-input-validation
              v-if="$can('read', 'purchasing.pricing')"
              v-model="w.purchasing_price"
              class="column is-2-tablet is-6-mobile"
              :disabled="!$can('update', 'purchasing.pricing')"
              :placeholder="placeholders.number.decimal_2"
              input-type="number"
              step=".01"
              vid="purchase_price"
              :suffix="w.currency.symbol"
              :label="$t('purchase_price')"
            />
            <div class="column is-2-tablet is-6-mobile">
              <b-select-validation
                v-model="w.car_category"
                :placeholder="$t('choose')"
                :label="`${$t('car_category')} (${$t('optional')})`"
                :disabled="disableWarehouseOptions"
              >
                <template>
                  <option
                    v-for="opt in carCategories"
                    :key="opt.id"
                    :value="opt.id"
                  >
                    {{
                      opt.value
                    }}
                  </option>
                </template>
              </b-select-validation>
            </div>
            <div class="column is-2-tablet is-6-mobile mt-2">
              <b-switch
                v-model="w.coming_soon"
                :disabled="disableWarehouseOptions"
              >
                {{
                  $t("coming_soon")
                }}
              </b-switch>
            </div> 
            <div class="column is-2-tablet is-6-mobile mt-2">
              <b-switch
                v-model="w.clearance"
                :disabled="disableWarehouseOptions"
              >
                {{
                  $t("clearance")
                }}
              </b-switch>
            </div> 
            <div class="column is-2-tablet is-6-mobile mt-2">
              <b-switch
                v-model="w.sample"
                :disabled="disableWarehouseOptions"
              >
                {{
                  $t("sample")
                }}
              </b-switch>
            </div>
          </div> 
        </div> 
           

        <div class="column mt-3">
          <b-field>
            <p class="control">
              <b-button
                type="is-primary"
                :disabled="options_loading || (disableOptions && disableWarehouseOptions)"
                :loading="options_loading"
                tag="input"
                native-type="submit"
                :value="$t('save')"
              >
                {{ $t("save") }}
              </b-button>
            </p>
            <p
              v-if="action == 'edit' && $can('update', 'catalog.bulk')"
              class="control"
            >
              <b-dropdown position="is-top-right">
                <template #trigger>
                  <b-button
                    type="is-primary"
                    icon-left="chevron-down"
                  />
                </template>

                <b-dropdown-item
                  :disabled="options_loading || (disableOptions && disableWarehouseOptions)"
                  @click="handleSubmit(() => confirmUpdating('size'))"
                >
                  {{ $tc("all_size", 1) }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="options_loading || (disableOptions && disableWarehouseOptions)"
                  @click="handleSubmit(() => confirmUpdating('color'))"
                >
                  {{ $t("all_color") }}
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="options_loading || (disableOptions && disableWarehouseOptions)"
                  @click="handleSubmit(() => confirmUpdating('product'))"
                >
                  {{ $t("all_product") }}
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </div>
    </form>
    <b-modal
      v-model="newAttribute"
      :width="520"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("add") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <component
          :is="component"
          :edit-obj="editObj"
          :types="newAttributeTypes"
          :languages="newAttributeTypesLangs"
          force-add
          disable-type
          @created="addItem"
          @cancel="newAttribute = false"
        />
      </Card>
    </b-modal>

    <b-modal
      v-model="showConfirmUpdate"
      :width="720"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("choose_variables") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-mobile">
          <div
            v-if="$can('update', 'catalog')"
            class="column is-12"
          >
            <div class="columns is-multiline is-mobile">
              <b-checkbox
                v-for="variable in updatingVars"
                :key="variable.value"
                v-model="variable.change"
                class="column is-4-tablet is-6-mobile mr-0 pb-2"
              >
                {{ variable.label }}
              </b-checkbox>
            </div>
          </div>
          <div
            v-if="$can('update', 'catalog') && $can('update', 'catalog.warehouses')"
            class="column is-12 pb-2"
          >
            <hr class="m-0 ">
          </div>
          <div
            v-if="$can('update', 'catalog.warehouses')"
            class="is-size-6 column is-12 pt-0 pb-2 pt-2"
          >
            <b>{{ $tc('warehouse', 2) }}</b>
          </div>
          <div
            v-if="warehouses.length > 1 && $can('update', 'catalog.warehouses')"
            class="column is-12"
          >
            <div class="columns is-multiline is-mobile mb-1">
              <div
                v-for="w in warehouses"
                :key="w.id"
                class="column is-4-tablet is-6-mobile"
              >
                <b-switch
                  v-model="w.warehouse_bulk"
                >
                  {{ w.country.country }} - {{ w.currency.code }}
                </b-switch>
              </div>
            </div>
          </div>
          <div
            v-if="$can('update', 'catalog.warehouses')"
            class="column is-12"
          >
            <div class="columns is-multiline is-mobile">
              <b-checkbox
                v-for="variable in updatingVarsWarehouse"
                :key="variable.value"
                v-model="variable.change"
                class="column is-4-tablet is-6-mobile mr-0 pt-2"
              >
                {{ variable.label }}
              </b-checkbox>
            </div>
          </div>
         
          <div class="column is-12 mt-2">
            <b-button
              class="mr-4"
              type="is-primary"
              :loading="options_loading"
              :disabled="options_loading"
              @click="save(true)"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              :disabled="options_loading"
              @click="cancelUpdating"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import sortableTagInput from "@/components/forms/elements/sortableTagInput";
import SearchProduct from "@/components/forms/elements/searchProduct.vue";

import multiSelect from "./MultiSelect";
export default {
  components: {
    sortableTagInput,
    multiSelect,
    SearchProduct
  },
  props: {
    prodId: {
      type: Number,
      required: true
    },
    sizesTypes: {
      type: Object,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    flavors: {
      type: Object,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
    tlds: {
      type: Array,
      required: true
    },
    sold: {
      type: Boolean,
      default: false
    },
    attributesProps: {
      type: Object,
      required: true
    },
    langs: {
      type: Array,
      required: true
    },
    countries: {
      type: Object,
      required: true
    },
    attributesTypes: {
      type: Array,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    carCategories: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showConfirmUpdate: false,
      updateType: null,
      updatingVars: [
        {
          label: this.$i18n.t("size"),
          value: "size",
          change: false
        },
        {
          label: this.$i18n.t("shopping_feed"),
          value: "shopping_feed",
          change: false
        },
         {
          label: this.$i18n.tc("pack", 1),
          value: "pack",
          change: false
        },       
        {
          label: this.$i18n.t("weight"),
          value: "weight",
          change: false
        },
        {
          label: this.$i18n.t("hs_code"),
          value: "hs_code",
          change: false
        }, 
        {
          label: this.$i18n.t("origin_iso"),
          value: "origin_iso",
          change: false
        },
        {
          label: this.$i18n.t("qty_palette"),
          value: "qty_palette",
          change: false
        },
        {
          label: this.$i18n.t("qty_carton"),
          value: "qty_box",
          change: false
        },
        {
          label: this.$i18n.t("parent_id"),
          value: "parent_id",
          change: false
        },          
      ],

      updatingVarsWarehouse: [
        {
          label: this.$i18n.tc("supplier",1),
          value: "supplier_id",
          change: false
        },
        {
          label: this.$i18n.t("purchase_price"),
          value: "purchase_price",
          change: false
        },
        {
          label: this.$i18n.t("coming_soon"),
          value: "coming_soon",
          change: false
        },
        {
          label: this.$i18n.t("clearance"),
          value: "clearance",
          change: false
        },
        {
          label: this.$i18n.t("sample"),
          value: "sample",
          change: false
        },
      ],

      warehouse_bulk: [],

      newAttribute: false,
      newAttributeTypes: [],
      newAttributeTypesLangs: [],
      component: null,
      editObj: {},
      type: "",
      logisticAttributes: this.attributesProps,
      qtyPalettes: this.attributesProps.logistic.find(
        item => item.key == "qty_palette"
      ),
      qtyBoxes: this.attributesProps.logistic.find(
        item => item.key == "qty_box"
      ),

      generalAttributesData: this.attributesProps.general,

      tempValues: [{ id: 1, label: "test" }],

      disableOptions: false,
      disableWarehouseOptions: false,
      warehouse_id: null,
      coming_soon: true,
      sample: false,
      pack: true,
      shopping_feed: false,
      clearance: false,
      label: false,
      weight: null,
      qty_palette: "",
      qty_box: "",
      parent_id: null,
      car_category: null,
      attribute_value: null,
      sizes: [],
      selected_size: "",
      selected_size_type: null,
      hs_code: null,
      origin_iso: null,
      attribute_label: "",
      attribute_id: "",
      attributes: [],
      generalAttributes: [
        {
          type: null,
          value: null
        }
      ],
      data_attribute: "",
      multiple_sizes: false,
      barcode: "",
      edited_barcode: "",
      options_loading: false,
      prices: [],
      purchase_price: null,
      warehouse: null,
      filteredWarehouses: this.warehouses,
      suppliers_required: "required",
    };
  },
  watch: {
    selected_size_type() {
      this.handleSizeTypeChange();
    }
  },
  mounted() {
    if (
      this.$can("create", "catalog") ||
      this.$can("update", "catalog") ||
      this.$can("read", "catalog")
    ) {
      let attribute;
      let attribute_type;
      if (this.flavors.selected) {
        attribute = this.flavors.selected;
        attribute_type = "flavor";
      } else if (this.colors.selected) {
        attribute = this.colors.selected;
        attribute_type = "color";
      } else {
        attribute = this.attribute;
        attribute_type = this.attribute;
      }
      this.origin_iso = this.countries.selected;
      this.changeAttribute(attribute_type);
      this.data_attribute = attribute;
    }
  },
  created() {
    if (this.action == "read" || 
    (this.action == "create" && !this.$can("create", "catalog"))||
    (this.action == "edit" && !this.$can("update", "catalog"))) {
      this.disableOptions = true;
    }
    if (this.action == "read" || 
    (this.action == "create" && !this.$can("create", "catalog.warehouses"))||
    (this.action == "edit" && !this.$can("update", "catalog.warehouses"))||
    (this.action == "clone" && !this.$can("update", "catalog.warehouses"))) {
      this.disableWarehouseOptions = true;
    }
    if (
      this.$can("read", "catalog") &&
      this.options !== null &&
      this.action != "create" &&
      this.action != "draft"
    ) {
      // #start# Affect selected values
      this.coming_soon = this.options.coming_soon;
      this.clearance = this.options.clearance;
      this.shopping_feed = this.options.shopping_feed;
      this.barcode = this.action === "clone" ? "" : this.options.barcode || "";
      this.weight = this.options.weight;
      this.label = this.options.label;
      this.qty_box = this.options.qty_box;
      this.qty_palette = this.options.qty_palette;
      this.parent_id = this.options.parent_id;    
      // this.warehouse_id = this.options.warehouse.id;
      this.sample = this.options.sample;
      this.pack = this.options.pack;
      this.hs_code = this.options.hs_code
      this.origin_iso = this.options.origin_iso;

      if (this.sizesTypes.selected.type_id) {
        this.setSizes();
      }
      if (this.action === "edit") {
        this.setAttributes();
      }
    }

    if (this.action != "edit" && this.sites) {
      this.setPrices();
    }

    for (let index = 0; index < this.generalAttributesData.length; index++) {
      const element = this.generalAttributesData[index];

      if (element.selected) {
        this.generalAttributes.unshift({
          type: element.id,
          value: element.selected
        });
      }
    }
    for (let i = 0; i < this.warehouses.length; i++) {
      this.warehouses[i].warehouse_bulk = true;
      this.warehouses[i].purchasing_price = this.warehouses[i].purchase_price && this.warehouses[i].purchase_price.value ? this.warehouses[i].purchase_price.value : null;
      this.warehouses[i].showDeleteWarehouse = this.warehouses[i].suppliers.selected != null;
      this.warehouses[i].rerender = true;
    }
    if (!this.$can("update", "purchasing.pricing")) {
      this.updatingVarsWarehouse.splice(1,1);
    }

    if (this.action == "create") {
      for (let i = 0; i < this.warehouses.length; i++) {
        this.warehouses[i].coming_soon = true;
      }
    }
    // #end# Affect selected values
  },
  methods: {
    confirmUpdating(type) {
      for (let i = 0; i < this.updatingVars.length; i++) {
        this.updatingVars[i].change = false;
      }
      for (let i = 0; i < this.updatingVarsWarehouse.length; i++) {
        this.updatingVarsWarehouse[i].change = false;
      }
      this.updateType = type;
      this.showConfirmUpdate = true;
    },
    cancelUpdating() {
      this.updateType = null;
      this.warehouse_bulk = [];
      this.showConfirmUpdate = false;
    },
    checkSuppliers() {
      if (this.disableWarehouseOptions) this.suppliers_required = "";
      else {
        for ( let i = 0; i < this.warehouses.length; i++) {
          if (this.warehouses[i].suppliers.selected) {
            this.suppliers_required = "";
            break;
          } else {
            this.suppliers_required = "required";
          } 
        }
      }
    },
    confirmDeleteWarehouse(warehouse_id, index) {
      this.$buefy.dialog.confirm({
          message: this.$t("are_you_sure"),
          confirmText: this.$t("delete"),
          cancelText: this.$t("cancel"),
          type: 'is-danger',
          hasIcon: false,
          onConfirm: () => this.deleteWarehouse(warehouse_id, index)   
      })
   },
    deleteWarehouse(warehouse_id, index) {
      this.$axios
        .delete(`catalog/sku/${this.$route.params.sku}/warehouse/${warehouse_id}`)
        .then(() => { 
          this.warehouses[index].suppliers.selected = null;
          this.warehouses[index].purchasing_price = null;
          this.warehouses[index].car_category = null;
          this.warehouses[index].coming_soon = null;
          this.warehouses[index].clearance = null;
          this.warehouses[index].sample = null;
          this.warehouses[index].rerender = false; 
          this.warehouses[index].showDeleteWarehouse = false; 
          setTimeout(() => {
            this.warehouses[index].rerender = true; 
          }, 5);
   
          this.$root.notify(this.$t("successfully_deleted"), "is-success");
        })
        .catch((e) => this.clientError(e));
    }, 
    addItem(item) {
      let index;
      switch (this.type) {
        case "size":
          index = this.sizesTypes.list.findIndex(
            s => s.id == this.editObj.type
          );
          this.sizesTypes.list[index].sizes.push({
            id: item.id,
            label: item.value.formatted
          });
          this.selected_size = item.id;
          break;
        case "flavor-color":
          this.attribute_value = item.id;
          this.attributes.push({
            attribute_id: this.editObj.type,
            id: item.id,
            label: item.value.value
          });
          if (this.editObj.type == this.flavors.id)
            this.flavors.list.push({
              attribute_id: this.editObj.type,
              id: item.id,
              label: item.value.value
            });
          else
            this.colors.list.push({
              attribute_id: this.editObj.type,
              id: item.id,
              label: item.value.value
            });
          break;
        case "qty_palette":
          this.qty_palette = item.id;
          this.qtyPalettes.list.push({
            id: item.id,
            value: item.value.formatted
          });
          break;
        case "qty_box":
          this.qty_box = item.id;
          this.qtyBoxes.list.push({
            id: item.id,
            value: item.value.formatted
          });
          break;
        default:
          index = this.generalAttributesData.findIndex(
            s => s.id == this.editObj.type
          );
          this.generalAttributesData[index].list.push({
            id: item.id,
            value: item.value.formatted
          });
          break;
      }
      this.newAttribute = false;
      this.editObj = {};
      this.component = null;
    },
    showNewAttribute(type, val, text) {
      if (type == "size") {
        this.component = () =>
          import("@/components/forms/Attributes/Sizes.vue");
        this.newAttributeTypes = this.sizesTypes.list;
      } else {
        this.component = () =>
          import("@/components/forms/Attributes/Attributes.vue");
        this.newAttributeTypes = this.attributesTypes;
        this.newAttributeTypesLangs = this.langs;
      }

      this.type = type;
      this.editObj = {
        type: val,
        typedValue: text
      };
      this.newAttribute = true;
    },
    getTypeData(type) {
      if (!type) return [];
      let typeObj = this.generalAttributesData.find(i => i.id == type);
      if (typeObj) return typeObj.list;
      return [];
    },
    setPrices() {
      let arr = [];
      let i;
      for (i = 0; i < this.sites.length; i++) {
        arr.push({ [this.sites[i].id]: [] });
        let j;
        for (j = 0; j < this.sites[i].tlds.length; j++) {
          arr[i][this.sites[i].id].push({
            [this.sites[i].tlds[j].id]: {
              base: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.base
                : "",
              selling: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.selling
                : "",
              purchase: this.sites[i].tlds[j].price
                ? this.sites[i].tlds[j].price.purchase
                : ""
            }
          });
        }
      }
      this.prices = JSON.parse(JSON.stringify(arr));
    },
    affectPriceSiteTld(prices, i, j) {
      prices.push({
        ...this.prices[i][this.sites[i].id][j][this.sites[i].tlds[j].id],
        site_id: this.sites[i].id,
        tld_id: this.sites[i].tlds[j].id
      });
    },
    setSizes() {
      let selected_size_type = this.sizesTypes.list.find(
        st => st.id === this.sizesTypes.selected.type_id
      );
      this.selected_size_type = selected_size_type.id;
      this.sizes = selected_size_type.sizes;
      this.handleSizeTypeChange();
      if (this.action === "edit") {
        let size = this.sizes.find(
          s => s.id === this.sizesTypes.selected.size_id
        );
        this.selected_size = size.id;
      }
    },
    setAttributes() {
      if (this.flavors.selected) {
        this.attribute_value = this.flavors.selected;
        this.attributes = this.flavors.list;
        this.data_attribute = "flavor";
      } else {
        this.attribute_value = this.colors.selected;
        this.attributes = this.colors.list;
        this.data_attribute = "color";
      }
    },
    handleSizeTypeChange() {
      let selected_size_type = this.sizesTypes.list.find(
        st => st.id === this.selected_size_type
      );
      this.sizes = selected_size_type.sizes;
      this.selected_size = "";
      if (selected_size_type.multiple && this.action != "edit") {
        this.multiple_sizes = true;
        this.sizes.map((s, i) => {
          this.sizes[i]["barcode"] = "";
        });
      } else this.multiple_sizes = false;
    },
    changeAttribute(newValue) {
      if (newValue === "flavor") {
        this.attribute_label = this.$i18n.tc("flavor", 1);
        this.attribute_id = this.flavors.id;
        this.attributes = this.flavors.list;
      } else if (newValue === "color") {
        this.attribute_label = this.$i18n.tc("color", 1);
        this.attribute_id = this.colors.id;
        this.attributes = this.colors.list;
      }
    },
    switchAttributes() {
      if (this.action === "create") {
        if (this.data_attribute === "color") {
          this.attribute_label = this.$i18n.tc("flavor", 1);
          this.attributes = this.flavors.list;
          this.attribute_id = this.flavors.id;

          this.data_attribute = "flavor";
        } else if (this.data_attribute === "flavor") {
          this.attribute_label = this.$i18n.tc("color", 1);
          this.attributes = this.colors.list;
          this.attribute_id = this.colors.id;
          this.data_attribute = "color";
        }
      }
    },
    save(isBulk = false) {
      let war = [];
      for (let i = 0; i < this.warehouses.length; i++) {
        let obj = {
          warehouse_id: this.warehouses[i].id,
          supplier_id: this.warehouses[i].suppliers.selected,
          purchase_price: this.warehouses[i].purchasing_price,
          coming_soon: this.warehouses[i].coming_soon,
          clearance: this.warehouses[i].clearance,
          car_category: this.warehouses[i].car_category,
          sample: this.warehouses[i].sample,
        }
        if (obj.supplier_id) war.push(obj);
      }
      let data = {
        coming_soon: this.coming_soon,
        noindex: this.noindex,
        clearance: this.clearance,
        shopping_feed: this.shopping_feed,
        size_type: this.selected_size_type,
        attribute: this.attribute_value,
        weight: this.weight,
        label: this.label,
        qty_box: this.qty_box,
        qty_palette: this.qty_palette,
        parent_id: this.parent_id,
        car_category: this.car_category,
        purchase_price: this.purchase_price,
        sample: this.sample,
        pack: this.pack,
        hs_code: this.hs_code,
        origin_iso: this.origin_iso,
        warehouses: war,
      };

      if (!this.multiple_sizes) {
        let barcode = this.barcode;
        let size = this.selected_size;
        data.sizes = [{ id: size, barcode: barcode }];
      } else {
        let sizes;
        if (typeof this.selected_size === "object")
          sizes = [...this.selected_size];
        else sizes = [...this.selected_size];
        data.sizes = sizes.map(s => ({ id: s.id, barcode: s.barcode }));
      }

      let generalAttributes = this.generalAttributes.reduce((arr, o) => {
        if (o.type && o.value) {
          return [...arr, { type_id: o.type, id: o.value }];
        } else {
          return arr;
        }
      }, []);
      if (generalAttributes.length > 0) {
        data.general_attributes = generalAttributes;
      }

      if (this.action == "create") {
        data.prices = null;
      } else if (this.action != "edit") {
        let prices = [];
        let i;
        for (i = 0; i < this.sites.length; i++) {
          let j;
          for (j = 0; j < this.sites[i].tlds.length; j++) {
            this.affectPriceSiteTld(prices, i, j);
          }
        }
        data.prices = prices;
      }

      if (isBulk) {
        let vars = this.updatingVars.filter(v => v.change);
        let varsWarehouse = this.updatingVarsWarehouse.filter(v => v.change);
        for (let v of varsWarehouse) { 
          vars.push(v);
        }

        data.bulk_variables = vars
          .reduce((arr, v) => [...arr, v.value], [])
          .join(",");
        data.bulk = this.updateType;
        data.warehouse_ids = [];
        for (let i = 0; i < this.warehouses.length; i++) {
          if (this.warehouses[i].warehouse_bulk == true) {
            data.warehouse_ids.push(this.warehouses[i].id);
          }
        }
      }

      let method;
      let url = "catalog/sku/" + this.$route.params.sku;
      if (
        this.action === "create" ||
        this.action === "clone" ||
        this.action === "draft"
      ) {
        method = "POST";
        url = "catalog/product/" + this.prodId + "/skus";
      } else {
        method = "PUT";
      }

      this.options_loading = true;
      this.$axios({
        method: method,
        url: url,
        data: data
      })
        .then(res => {
          this.warehouse_bulk = [];
          if (this.action === "edit") {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
            for (let i = 0; i < this.warehouses.length; i++) {
              this.warehouses[i].showDeleteWarehouse = res.data.filters.warehouses[i].suppliers.selected != null;
            } 
          } else {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
          this.$emit("changeStep", {
            step: "pricing",
            id: this.prodId,
            skus: res.data.skus,
            optionsSaved: true
          });
        })
        .catch(e => this.clientError(e))
        .finally(() => {
          this.cancelUpdating();
          this.options_loading = false;
        });
    }
  }
};
</script>
